import fetchWithRetry from './fetchWithRetry';
import type RequestOptions from './RequestOptions';
import type MailboxRequestOptions from './MailboxRequestOptions';
import { getConfig, type ServiceRequestConfig } from './config';
import checkAndLogMailboxInfo from './checkAndLogMailboxInfo';
import { getApp } from 'owa-config';
import { debugErrorThatWillShowErrorPopupOnly, trace } from 'owa-trace';
import getUrlWithAddedQueryParameters from 'owa-url/lib/getUrlWithAddedQueryParameters';
import { checkMailboxInfoForDiagnosticData } from './utils/checkMailboxInfoForDiagnosticData';
import { isServiceRequestSupportedForMailbox } from './utils/isServiceRequestSupportedForMailbox';
import { disallowedLicensingCallCheck } from './utils/disallowedLicensingCallCheck';
import { isSuccessStatusCode } from 'owa-http-status-codes';
import { netcoreCustomComparator } from './netcoreCustomComparator';
import { isBootFeatureEnabled } from 'owa-metatags';

export function makeServiceRequest<T>(
    actionName: string,
    parameters: any,
    options?: RequestOptions | MailboxRequestOptions
): Promise<T> {
    trace.info(`ServiceAction ${actionName}`, 'service');

    checkMailboxInfoForDiagnosticData(options?.mailboxInfo);
    if (!isServiceRequestSupportedForMailbox(options?.mailboxInfo)) {
        const msg = 'Cannot issue OWS request for a mailbox that does not support service requests';
        debugErrorThatWillShowErrorPopupOnly(msg);
        throw new Error(msg);
    }

    // Check to make sure this is not a disallowed licensing call
    disallowedLicensingCallCheck(options?.mailboxInfo, actionName);

    if (options?.mailboxInfo?.isRemoved) {
        debugErrorThatWillShowErrorPopupOnly('InvalidRequest: MailboxInfo was removed');
        throw new Error('InvalidRequest: MailboxInfo was removed');
    }

    const config = getConfig();
    checkAndLogMailboxInfo(config, 'Acct-MakeServiceRequestMailbox', options?.mailboxInfo);

    let endpoint;
    if (options?.endpoint) {
        endpoint = options.endpoint;
    } else {
        const baseUrl = options?.customBaseUrl
            ? options.customBaseUrlSubPath
                ? options.customBaseUrl.concat(options.customBaseUrlSubPath)
                : options.customBaseUrl
            : options?.customBaseUrlSubPath
            ? config.baseUrl.concat('/' + options.customBaseUrlSubPath)
            : config.baseUrl;
        endpoint = `${baseUrl}/service.svc?action=${actionName}`;
    }

    if (
        (config.isUserIdle !== undefined && config.isUserIdle()) ||
        options?.isUserActivity == false
    ) {
        endpoint = getUrlWithAddedQueryParameters(endpoint, { UA: '0' });
    }

    endpoint = getUrlWithAddedQueryParameters(
        endpoint,
        { app: getApp() },
        true /* ignore if present */
    );

    if (isBootFeatureEnabled('fwk-netcore-api') && isNetCoreAPI(actionName, config)) {
        if (Object.keys(netcoreCustomComparator).includes(actionName)) {
            return fetchComparison(actionName, endpoint, options, parameters);
        }
        return fetchNetCore(actionName, endpoint, options, parameters, true /* fallback */);
    }
    return fetchWithRetry(actionName, endpoint, 1, options, parameters);
}

function isNetCoreAPI(actionName: string, config: ServiceRequestConfig) {
    const setting = config.getApplicationSettings?.('NetCore');
    return (
        !setting?.disabledAPIs.includes(actionName) &&
        (setting?.enabledAPIs.includes(actionName) || setting?.enabledAPIs.includes('*'))
    );
}

function fetchNetCore(
    actionName: string,
    endpoint: string,
    options?: RequestOptions | MailboxRequestOptions,
    parameters?: any,
    fallback?: boolean
) {
    const netcoreEndpoint = endpoint.replace('/owa/', '/owanetcore/');
    const wantJsonResponse = !options?.returnResponseHeaders;
    const netcoreOptions = { ...options, returnResponseHeaders: true };
    return fetchWithRetry(actionName, netcoreEndpoint, 1, netcoreOptions, parameters)
        .then(response => {
            if (typeof response.status === 'number' && !isSuccessStatusCode(response.status)) {
                throw new Error('owanetcore response did not succeed');
            }
            if (wantJsonResponse) {
                return response.json();
            }
            return Promise.resolve(response);
        })
        .then(response => response)
        .catch(error => {
            if (fallback) {
                return fetchWithRetry(actionName, endpoint, 1, options, parameters);
            }
            throw error;
        });
}

function fetchComparison(
    actionName: string,
    endpoint: string,
    options?: RequestOptions | MailboxRequestOptions,
    parameters?: any
) {
    return fetchWithRetry(actionName, endpoint, 1, options, parameters).then((owaResponse: any) => {
        return fetchNetCore(actionName, endpoint, options, parameters, false /* fallback */)
            .then((netcoreResponse: any) => {
                return (
                    netcoreCustomComparator[actionName]?.(owaResponse, netcoreResponse) ??
                    owaResponse
                );
            })
            .catch(() => {
                return owaResponse;
            });
    });
}
