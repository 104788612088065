import { logStartUsage, logStartGreyError } from 'owa-analytics-start';
import { getSerializationIssue } from './serialization/getSerializationIssue';
import { serialize } from './serialization/serialize';
import type { SerializationIssue } from './types/SerializationTypes';
import { traverseObjectDeep } from './utils/traverseObjectDeep';
import { getSerializationError } from './serialization/getSerializationError';
import type { ExtendedWorkerOptions } from 'owa-analytics-types';

export function postMessageWrapper(
    _this: MessagePort | Worker,
    pPostMessage: MessagePort['postMessage'],
    message: any,
    options?: any,
    workerName?: ExtendedWorkerOptions['workerName']
) {
    const STACK_SIZE_LIMIT = Error.stackTraceLimit;
    // If available, increase the stack trace limit from 10 to 50 se we can see the repeating pattern
    if (Error.stackTraceLimit) {
        Error.stackTraceLimit = 50;
    }

    try {
        pPostMessage.call(_this, message, options);
    } catch (error) {
        if (isDataCloneError(error) && message) {
            const allSerializationIssues = findAllSerializationErrors(message);

            const { retried, retriedFailed } = retryForAnalytics(
                error,
                _this,
                pPostMessage,
                message,
                options,
                workerName
            );

            const count = allSerializationIssues.length;
            const issue = count > 0 ? allSerializationIssues[0] : null;

            const errorWithDetails = getSerializationError(
                error,
                issue,
                retried,
                retriedFailed,
                count
            );
            logStartGreyError('SerializationError_Cloning', errorWithDetails, {
                diagnostic: errorWithDetails?.diagnosticInfo,
            });
        } else {
            // Log other types of errors with postMessage
            const errorWithDetails = getSerializationError(error, null, false, false);
            logStartGreyError('SerializationError_Other', errorWithDetails, {
                diagnostic: errorWithDetails?.diagnosticInfo,
            });
        }
    }

    // Reset to default limit
    if (Error.stackTraceLimit) {
        Error.stackTraceLimit = STACK_SIZE_LIMIT;
    }
}

function retryForAnalytics(
    error: Error,
    _this: MessagePort | Worker,
    pPostMessage: MessagePort['postMessage'],
    message: any,
    options?: any,
    workerName?: ExtendedWorkerOptions['workerName']
): {
    retried: boolean;
    retriedFailed: boolean;
} {
    let retried = false;
    let retriedFailed = false;

    /**
     * We only retry for the Analytics Web Worker because we do not
     * know what format is expected by other workers.
     */
    if (workerName === 'AnalyticsWorker') {
        // Try calling post message again with the serialized message.
        try {
            retried = true;
            message = serialize(message);
            pPostMessage.call(_this, message, options);
        } catch (retryError) {
            retriedFailed = true;

            // Only log an issue when we get two different cloning errors.
            if (error?.message !== retryError?.message) {
                logStartUsage('SerializationRetryFailed', {
                    error1: error?.message,
                    error2: retryError?.message,
                    attemp1: error?.stack?.slice(0, 1024),
                    attemp2: retryError?.stack?.slice(0, 1024),
                });
            }
        }
    }

    return {
        retried,
        retriedFailed,
    };
}

function isDataCloneError(error: Error) {
    return (
        error?.name === 'DataCloneError' ||
        error?.message?.includes("Failed to execute 'postMessage") ||
        error?.message?.includes('Data cannot be cloned')
    );
}

function findAllSerializationErrors(value: any) {
    let issues: SerializationIssue[] = [];

    // Check for known serialization issues
    if (typeof value === 'object') {
        issues = traverseObjectDeep<SerializationIssue>(value, checkKnowSerializationIssue);
    }

    return issues;
}

function checkKnowSerializationIssue(parent: any, key: string | number): SerializationIssue | null {
    const issue = getSerializationIssue(parent, key);

    if (issue) {
        issue.key = key;
    }

    return issue;
}
